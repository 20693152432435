.cart {
  width: 39rem;
  background-color: white;
  border-radius: 1.1rem;

  font-family: "Readex Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  opacity: 0;
}

.cart[data-visible] {
  animation: show 0.2s ease forwards;
}

.cart:not([data-visible]) {
  display: none;
}

.cart[data-hiding] {
  display: block;
  pointer-events: none;
  animation: hide 0.2s ease forwards;
}

.cartWrapper {
  padding: 2rem 2.6rem;
  border: 0.1rem solid #e5e7eb;

  border-radius: 1.1rem;
}

.cartHeader {
  font-family: inherit;
  text-align: center;

  font-weight: 900;
  font-size: 1.6rem;
}

.cartContent {
  padding: 1rem 0 2.5rem 0;
}

.cartItem {
  display: flex;
  align-items: center;
  flex-direction: row;

  padding: 1.5rem 0;

  border-bottom: 0.1rem solid #e5e7eb;
}

.cartItem__image {
  width: 7.2em;
  height: 7.2rem;
  background-color: #f5f5f5;
}

.cartItem__info {
  flex: 1;
  margin-left: 1.6rem;

  font-size: 1.6rem;
  font-weight: 500;
}

.cartItem__price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  font-size: 1.6rem;
  font-weight: 300;
}

.cartItem__buttonRemove {
  font-size: 1.2rem;

  text-decoration: underline;
  text-underline-offset: 0.2rem;

  text-transform: uppercase;
}

.cartFooter {
}

.cartFooter__total {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 900;

  text-align: right;
}
.cartFooter__total p:first-child {
  margin-right: 1rem;
}

.cartFooter__info {
  font-size: 1.2rem;
  font-weight: 400;

  text-align: right;
  margin-bottom: 2rem;
}

.cartFooter__checkout {
  display: flex;
  justify-content: center;
}
.cartFooter__checkoutButton {
  border: 0.1rem solid #e5e7eb;
  border-radius: 1.7rem;

  height: 3.4rem;
  width: 10.4rem;

  font-size: 1.6rem;
  font-weight: 500;

  margin: auto;
}

@media screen and (max-width: 900px) {
  .cart {
    width: 100vw;
    height: calc(100vh - 7rem);
    padding-top: 8.7rem;
  }
  .cartWrapper {
    border: none;
  }
  .cartItem__image {
    width: 10rem;
    height: 10rem;
  }
  .cartHeader,
  .cartItem__info,
  .cartItem__price,
  .cartFooter__total,
  .cartFooter__checkoutButton {
    font-size: 2.2rem;
    line-height: normal;
    font-size: 400;
  }

  .cartItem__buttonRemove {
    font-size: 1.6rem;
  }

  .cartFooter__checkoutButton {
    height: 4.71rem;
    width: 14.1rem;
    border-radius: 2.3rem;
  }

  .cartFooter__info {
    text-align: center;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
