.mobileMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;
  background-color: var(--color-primary);
  background-color: #c87448;
  z-index: 1000;
  overflow: hidden;

  transition: width 0.3s ease-in-out;

  touch-action: none;
}

.mobileMenu__opened {
  width: 100%;
}

.mobileMenu__closed {
  width: 0;
}

.mobileMenu__top {
  display: flex;
  align-items: center;
  padding: 2rem;
  padding-top: 2.4rem;
  transition: opacity 0.1s ease-in-out;
}
.mobileMenu__opened .mobileMenu__top,
.mobileMenu__opened .mobileMenu__content {
  opacity: 1;
}

.mobileMenu__closed .mobileMenu__top,
.mobileMenu__closed .mobileMenu__content {
  opacity: 0;
}

.mobileMenu__top svg {
  fill: white;
}

.mobileMenu__content {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  transition: opacity 0.1s ease-in-out;

  touch-action: none;
}

.mobileMenu__navigationMenu {
  position: absolute;

  padding: 5rem 0;
  text-align: center;

  transition: opacity 0.1s ease-in-out;
}

.mobileMenu__navigationMenu a {
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.15rem;
}

.mobileMenu__content ul {
  list-style: none;
  padding: 0;
  text-transform: uppercase;

  color: white;
  font-size: 3rem;
  font-weight: 400;
  line-height: 6rem;

  padding-bottom: 3.5rem;
}

.mobileMenu__opened .mobileMenu__content ul li,
.mobileMenu__opened .mobileMenu__content p {
  animation: fadeAndSlideIn;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}

.mobileMenu__content ul li:nth-child(1) {
  animation-duration: 1s;
}
.mobileMenu__content ul li:nth-child(2) {
  animation-duration: 0.95s;
}
.mobileMenu__content ul li:nth-child(3) {
  animation-duration: 1s;
}
.mobileMenu__content ul li:nth-child(4) {
  animation-duration: 0.9s;
}
.mobileMenu__content ul li:nth-child(5) {
  animation-duration: 0.85s;
}
.mobileMenu__content ul li:nth-child(6) {
  animation-duration: 0.8s;
}

.mobileMenu__content ul li img {
  margin: auto;
}

.mobileMenu__content p {
  color: white;
  font-size: 1.6rem;
  font-weight: 400;
}

.mobileMenu__copyright {
  font-size: 1.6rem;
}

.mobileMenu__tosMenu {
  position: absolute;
  height: 100%;
  overflow-y: scroll;

  transition: opacity 0.1s ease-in-out;
}

.mobileMenu__tosMenuWrapper {
  height: max-content;
  padding: 0 3rem 6rem;
}

.mobileMenu__tosMenuWrapper * {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.5rem;
  color: white;
}
.mobileMenu__tosMenuWrapper p {
  font-weight: 300;
  line-height: 2.5rem;
  margin: 0;
  padding: 0;
}

.mobileMenu__tosMenuWrapper h2 {
  margin: 2.5rem 0;
}

.mobileMenu__content.navigation .mobileMenu__navigationMenu,
.mobileMenu__content.tos .mobileMenu__tosMenu {
  opacity: 1;
  z-index: 10;
}
.mobileMenu__content.tos .mobileMenu__navigationMenu,
.mobileMenu__content.navigation .mobileMenu__tosMenu {
  opacity: 0;
  z-index: -1;
}

@keyframes fadeAndSlideIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
    transform: translateX(-200%);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
    transform: translateX(0);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 530px) {
  .mobileMenu__content ul {
    font-size: 2rem;
    line-height: 4rem;

    padding-bottom: 2.5rem;
  }
}
