.shop__title {
  font-size: 2rem;
}

.shop__products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 2rem;
  overflow-y: scroll;
}

.shop__product {
  flex: 0 1 24rem;
  position: relative;
  margin: 1rem;
}

.shop__product img {
  transition: filter 0.2s ease-in-out;
}

.shop__productInfoContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 14.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 70%;

  transition: all 0.2s ease;
}

.shop__productInfo,
.shop__productActions {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop__productActions {
  justify-content: space-between;
}

.shop__productName,
.shop__productPrice {
  font-family: "Readex Pro";
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 21.25px;
  text-align: center;
  color: white;
  text-transform: uppercase;
}

.shop__productPrice {
  font-weight: 300;
}

.shop__productActionButton {
  cursor: pointer;

  border-radius: 1.5rem;
  border: 0.075rem solid white;

  color: white;
  font-family: Readex Pro;
  font-size: 1.491rem;
  font-weight: 300;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  text-wrap: nowrap;
  padding: 0.8rem 1rem;
  margin: 0.5rem 0;

  transition: all 0.2s ease;
}
.shop__productActionButton:hover {
  background-color: white;
  color: black;
}

.shop__productActionButton:focus {
  color: white;
  background-color: #c87448;
  border-color: #b2663f;
  transition: all 0.1s ease;
}

.shop__cartPosition {
  position: absolute;
  top: 7rem;
  right: 1rem;

  transition: all 0.2s ease;
}

.hidden {
  display: none;
  pointer-events: none;
}

@media screen and (max-width: 900px) {
  .shop__products {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .shop__cartPosition {
    right: 0;
    width: 100vw;
  }
}

@media screen and (max-width: 530px) {
  .shop__productName,
  .shop__productPrice {
    font-size: 1.55rem;
    line-height: 21px;
  }

  .shop__productActionButton {
    font-size: calc(100vw / 530 * 15);
    padding: 0.6rem 0.75rem;
    margin: 0.5rem 0;
  }
}

@media (hover: hover) {
  .shop__product:hover img {
    filter: brightness(0.3);
  }
}
