.detail__productDetail {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;

  padding-top: 2.5rem;

  font-size: calc(100vw / 1710 * 10);
  line-height: 1;
  font-family: "Readex Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

@media screen and (max-width: 900px) {
  .detail__productDetail {
    flex-direction: column;
    align-items: center;
  }
}

.detail__productDetailLeft {
  position: relative;
}

.detail__productDetailLeft,
.detail__productDetailRight {
  max-width: 71.8rem;
}

.detail__productDetailInfo {
  height: 71.8rem;
  width: 71.8rem;
  padding: 1rem 0 3rem 10rem;
}

.detail__productDetailInfoWrapper {
  width: calc(71.8rem / 2);
  height: 69.9rem;
  display: flex;
  flex-direction: column;

  color: black;
}

.detail__mainImageContainer {
  height: 71.8rem;
  width: 71.8rem;
  background-color: #f5f5f5;
}

@media screen and (max-width: 900px) {
  .detail__mainImageContainer,
  .detail__productDetailLeft,
  .detail__productDetailRight {
    max-width: none;
    width: 100%;
  }

  .detail__productDetailInfo {
    padding: 5rem 3rem;
    margin-bottom: 10rem;
    width: 100%;
  }

  .detail__mainImageContainer {
    height: 100vw;
  }
  .detail__productDetailInfoWrapper {
    width: auto;
  }
}

.detail__mainImage {
  margin: auto;
  height: 100%;
}

.detail__productTitle {
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
}

.detail__productPrice {
  font-size: 2rem;
  font-weight: 400;
  line-height: 3.5rem;
}

.detail__productPrice::after {
  content: " USD";
}

.detail__productSubtitle {
  margin-top: 2rem;

  font-size: 1.5rem;
  line-height: normal;
  font-weight: 500;
}

.detail__productDetailInfoActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
}

.detail__productDetailInfoActionsSize,
.detail__productDetailInfoActionsQuantity {
  margin: 10px 0;
}

.detail__productDetailInfoActionsSize > label,
.detail__productDetailInfoActionsQuantity > label {
  display: block;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.detail__productQuickBuyButton {
  /* height: 3.4rem; */
  width: 12.4rem;

  background-color: #c87448;
  border: 0.1rem solid #b2663f;
  border-radius: 1.7rem;

  padding: 1rem 0;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  line-height: 1rem;

  /* text-align: center; */
  text-align: center;
  text-wrap: nowrap;
  color: white;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
}

.detail__productAddToCart {
  height: 3.4rem;
  width: 13.9rem;
  color: black;
  border: 0.1rem solid #e5e7eb;
  border-radius: 1.7rem;
  margin-top: 0.5rem;
  transition: all 1.5s ease;
}
.detail__productAddToCart:focus {
  color: white;
  background-color: #c87448;
  border-color: #b2663f;
  transition: all 0.1s ease;
}
.detail__productAddToCart::after {
  content: "Add to Cart";

  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
}

.detail__productDescription {
  margin-top: 3.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: normal;
  font-family: "Readex Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.detail__selection {
  border: 0.075rem solid black;
  padding: 0.5rem;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;

  width: max-content;
}

/**
  * Used only because there is one size available at current stage
  */
.detail__noBorder {
  border: none;
  padding-left: 0;
  padding-top: 0;
}

/**
  * IMAGES CIRCLES
  */
.detail__circleContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.detail__circle {
  cursor: pointer;
  margin: 0 0.5rem;

  border-radius: 50%;
  border: 0.1rem solid #d9d9d9;
  width: 1.3rem;
  height: 1.3rem;
}
@media screen and (max-width: 900px) {
  .detail__circle {
    width: 1rem;
    height: 1rem;
  }
}

.detail__circleActive {
  background-color: #d9d9d9;
}

/**
  * INPUT 
  */
.detail__quantityInput {
  display: flex;
  align-items: center;
}

.detail__quantityButton {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
}

/* Remove the default number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  width: max-content;
  width: 5rem;
  height: 100%;
  text-align: center;
}

select,
input[type="number"] {
  font-family: "Readex Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

@media screen and (min-width: 801px) and (max-width: 1400px) {
  .detail__productDetailLeft,
  .detail__productDetailRight {
    max-width: 65rem;
  }
  .detail__mainImageContainer,
  .detail__productDetailInfo {
    width: 65rem;
    height: 65rem;
  }

  .detail__productDetailInfoActions {
    margin-top: 3rem;
  }
  .detail__productDescription {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .detail__mainImageContainer,
  .detail__productDetailInfo {
    width: 100%;
  }
  .detail__mainImage {
    object-fit: contain;
    max-width: none;
  }
  .detail__productDescription {
    text-wrap: auto;
    padding-bottom: 2rem;
  }
}
