@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "Readex Pro";
  src: local("monospace");
  unicode-range: U+0061;
}

html {
  font-size: calc(100vw / 1710 * 10);

  font-family: "KOBO-custom", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

@media screen and (max-width: 1400px) {
  html {
    font-size: calc(100vw / 1400 * 10);
  }
}

@media screen and (max-width: 1000px) {
  html {
    font-size: calc(100vw / 1000 * 10);
  }
}

@media screen and (min-width: 531px) and (max-width: 900px) {
  html {
    font-size: calc(100vw / 530 * 10);
  }
}

@media screen and (max-width: 530px) {
  html {
    font-size: 10px;
  }
}

body {
  background-color: #f8fafc;
}

.font-primary {
  font-family: "KOBO-custom", "Readex Pro", sans-serif;
}

.checkbox-custom {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
}

.checkbox-custom input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.checkbox-custom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #555;
  border-radius: 50%;
}

.checkbox-custom input[type="checkbox"]:checked::before {
  background-color: #555;
}

body {
  background-color: white;
}

.opaque-sticker {
  opacity: 0.3; /* O un altro valore a tua scelta */
}

.custom-loader {
  width: 25px;
  height: 25px;
  background: url("./asset/Loading.svg");
  animation: pulse 750ms infinite ease-in-out;
}
@keyframes pulse {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1.7);
  }
}

#companion {
  /* Nascondi l'aspetto predefinito */
  -webkit-appearance: none;
  appearance: none;
  background: url("./asset/selection_1.svg") no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

#companion:hover {
  background: url("./asset/selection_2_over.svg") no-repeat;
  background-size: contain;
}

#companion:checked {
  background: url("./asset/selection_3.svg") no-repeat;
  background-size: contain;
}

#selfie {
  /* Nascondi l'aspetto predefinito */
  -webkit-appearance: none;
  appearance: none;
  background: url("./asset/selfie_1.svg") no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

#selfie:hover {
  background: url("./asset/selfie_2.svg") no-repeat;
  background-size: contain;
}

#selfie:checked {
  background: url("./asset/selfie_3.svg") no-repeat;
  background-size: contain;
}

@keyframes floatAnimation {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(5px, 5px);
  }
}

.float {
  animation: floatAnimation 3s ease-in-out infinite;
}

.trigger-lorem:hover .hover-target {
  display: block; /* o `inline` a seconda del layout desiderato */
}

.footer-closed {
  /*position: fixed;*/
  /*bottom: -50%; /* o la misura esatta per nascondere tutto tranne l'immagine del footer */
  /*left: 0;
    right: 0;*/
  transition: bottom 0.5s;
  /*z-index: 100;*/
}

.footer-open {
  /*bottom: 66%; /* o quanto basta per mostrare il footer come desiderato */
  /*height: 70%;*/
}

/* Personalizza l'aspetto della scrollbar globale */
::-webkit-scrollbar {
  width: 10px; /* Larghezza della scrollbar */
  display: none;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: #f1f1f1; /* Colore del cursore grigio opaco */
  height: 20px; /* Altezza del cursore */
  border-radius: 10px; /* Angoli arrotondati del cursore */
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

/* Prefissa le regole della scrollbar con la classe custom-scroll */
.custom-scroll::-webkit-scrollbar {
  width: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #f1f1f1; /* Colore del cursore grigio opaco */
  height: 3px; /* Altezza del cursore */
  border-radius: 10px; /* Angoli arrotondati del cursore */
}

.custom-scroll::-webkit-scrollbar-track {
  background: transparent; /* Colore di sfondo del tracciato grigio chiaro */
  border-radius: 10px;
}

.scaled-canvas {
  transform: scale(0.8);
  /* transform-origin: top right; /* Imposta l'origine della trasformazione in alto a sinistra */
}

.smaller-div-canvas {
  height: 500px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.rotate {
  animation: rotate 5s linear infinite;
}

#tree-nation-offset-website > * {
  width: 100% !important;
}

.workshop {
  overflow: hidden;
}
